<template>
  <div>
    <fb:login-button scope="public_profile,email" onlogin="checkLoginState();" />
    <div id="status" />
  </div>
</template>
<script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>
<script>
 export default {
    data: () => ({

    }),
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js')
      document.head.appendChild(recaptchaScript)
    },
    methods: {
      openloginfb(){
        window.open('https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=879826764139180&redirect_uri=https://crm.salomhayot.uz/#/fb/login&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish')
      }
    }
  }
function statusChangeCallback(response) {  // Called with the results from FB.getLoginStatus().
  console.log('statusChangeCallback');
  console.log(response);                   // The current login status of the person.
  if (response.status === 'connected') {   // Logged into your webpage and Facebook.
    testAPI();
  } else {                                 // Not logged into your webpage or we are unable to tell.
    document.getElementById('status').innerHTML = 'Please log ' +
      'into this webpage.';
  }
}

function checkLoginState() {               // Called when a person is finished with the Login Button.
  FB.getLoginStatus(function (response) {   // See the onlogin handler
    statusChangeCallback(response);
  });
}

window.fbAsyncInit = function () {
  FB.init({
    appId: '1068958231292063',
    cookie: true,                     // Enable cookies to allow the server to access the session.
    xfbml: true,                     // Parse social plugins on this webpage.
    version: 'v21.0'           // Use this Graph API version for this call.
  });

  FB.getLoginStatus(function (response) {   // Called after the JS SDK has been initialized.
    statusChangeCallback(response);        // Returns the login status.
  });
};

function testAPI() {                      // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
  console.log('Welcome!  Fetching your information.... ');
  FB.api('/me', function (response) {
    console.log('Successful login for: ' + response.name);
    document.getElementById('status').innerHTML =
      'Thanks for logging in, ' + response.name + '!';
  });
}

</script>
